#mainExt {
  background-color: #333333;
  height: 100%;
  width: 81%;
  position: fixed;
  top: 0px;
  left: 19%;
  margin-left: 2px;
  text-align: center;
  color: white;
}

.extensionsDiv {
  width: 100%;
  margin-top: 5%;
  margin-left: 2%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.excelSection {
  width: 45%;
  margin-left: 4%;
  margin-right: 1%;
}

.excelTitle {
  width: 85%;
  border-bottom: 2px solid lightgrey;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding-left: 5px;
}

.excelDes {
  width: 90%;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19.5px;
}

.instructionsList {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

#toExcelStore {
  height: 85px;
  width: 193px;
  padding-top: 5px;
  background-color: #0be06e;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 20%;
}
#toExcelStore:hover {
  background-color: #008fff;
  color: white;
}
#toExcelStore:active {
  background-color: #008fff;
  color: white;
  box-shadow: 0px 0px 4px 2px #00B4FF;
}

#pluginGettingStarted {
  width: 42%;
  border-radius: 6px;
  background: linear-gradient(125deg, #29a55b, #3e7cab);
  padding-left: 30px;
}