#signUp {
    width: 120px;
    height: 35px;
    position: relative;
    top: -5px;
    background-color: #0be06e;
    border: 2px solid #0be06e;
    text-align: center;
    border-radius: 6px;
    font-weight: 300;
    letter-spacing: 1px;
}
#signUp:hover {
    background-color: #008FFF;
    border: 2px solid #008FFF;
}
#signUp:hover h3 {
    filter: invert(1);
}
#signUp:active{
    background-color: #008fff;
    border: 1px solid #008fff;
    box-shadow: 0.5px 0.5px 4px 2px #00B4FF;
}
#signUp:active h3 {
    filter: invert(0);
}

#userGroup {
    display: flex;
    justify-content: flex-start;
    margin-top: 33px;
    margin-right: 25px;
}



#signUp:active {
    background-color: #008FFF;
}




#signUp:active {
    background-color: #008FFF;
}
.hello{
    text-align: left;
}
.header{
    margin: auto; 
}
.lock-scroll{
overflow: hidden;
}






