.mainDocs {
    background-color: #333333;
    height: 5000px;
    width: 81%;
    position: absolute;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-align: center;
    color: white;
}

.fgDiv {
    position: relative;
    width: 90%;
    left: 5%;
    text-align: left;
    align-items: left;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.introTitle {
    height: 25px;
    width: 100%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.subTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.introMain {
    align-self: left;
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsTitle {
    height: 25px;
    width: 100%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.basicsa {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsb {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsc {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.feDiv {
    position: relative;
    width: 90%;
    left: 5%;
    text-align: left;
    align-items: left;
    border-bottom: 2px solid black;
}

.titleSpan {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 300;
}

.contentDiv {
    width: 94%;
    margin-left: 3%;
}

.exampleText {
    text-align: center;
    width: 90%;
    font-family: "Inter Tight", sans-serif;
    font-weight: 300;
}

#docsLink {
    color: #09CB62;
    text-decoration: none;
}
#docsLink:hover {
    color: #008FFF;
    text-decoration: underline;
}
#docsLink:active {
    color: #008FFF;
    text-decoration: wavy;
}