.FormGroup {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #ffffff;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #4ad388;
	border-radius: 4px;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

#discountCodeContainer {
	display: flex;
	margin: 0px 15px 5px 15px;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 12px;
	height: 45px;
	background-color: #ffffff;
	border-radius: 4px;
}

#discountCodeTitle {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 17px;
	color: black;
	margin-right: 40%;
	margin-top: 5px;
	height: 30px;
	justify-content: center;
	width: 30%;
	text-align: left;
}

#codeInput {
	color: black;
	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	height: 25px;
	width: 30%;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 16px;
	margin-top: 2.5px;
}

.buyButton {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 30px 15px 15px 15px;
	background-color: #008FFF;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
	font-size: 19px;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}
.buyButton:hover {
	background-color: #09CB62;
}
.buyButton:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}

.App {
	margin-left: 25%;
	margin-top: 5%;
	margin-right: 25%;
}