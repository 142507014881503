.gettingStartedContainer {
    height: 650px;
    width: 95%;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    padding-left: 35px;
    padding-right: 35px;
    color: white;
    border-left: 1px solid grey;
    padding-top: 20px;
}

#gsTitle {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
}

#gsIntro {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px;
}

#gsPluginContainer {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

#gsExplain {
    width: 75%;
    margin-left: 12.5%;
    font-size: 17px;
    font-weight: 300;
}

#gsButton {
    margin-bottom: 20px;
    height: 30px;
    width: 50%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#gsButton:hover {
    background-color: #008fff;
    color: white;
}
#gsButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

#gsWebContainer {
    border-bottom: 1px solid grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

#gsContactContainer {
    padding-top: 15px;
}

#gsContactEmail {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 20px;
}