.mainAcc {
    background-color: #333333;
    height: 150%;
    width: 81%;
    position: absolute;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
}

.topContainer {
    height: 150px;
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: flex-start;
    border-radius: 6px;
    margin-top: 40px;
}

.profileContainer {
    width: 70%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(237deg, #288150, #3e7cab);
    display: flex;
    justify-content: flex-start;
}

#profileInfo {
    margin-left: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: white;
    text-align: left;
}

#profileName {
    font-size: 35px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    height: 35px;
    margin-top: 15px;
}

#profileEmail {
    margin-top: -25px;
    font-size: 20px;
}

.dateContainer {
    width: 20%;
    margin-left: 5%;
    height: 60%;
    margin-top: 22.5px;
    border-right: 1px solid lightgrey;
    text-align: right;
}

#dateTitle {
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: lightgrey;
    margin-top: 10px;
    height: 15px;
    margin-right: 12px;
}

#dateMessage {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    color: white;
    margin-top: 0px;
    margin-right: 12px;
}

.middleContainer {
    display: flex;
    justify-content: flex-start;
    height: 500px;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    border-radius: 6px;
}

.infoContainer {
    width: 60%;
    height: 100%;
    color: white;
    text-align: left;
}

#infoTitle {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    margin-left: 0px;
}

.infoGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: lightgrey;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    border-top: 1px solid lightgrey;
}

#infoSpan {
    font-size: 22px;
    color: white;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
}

#bottomSpan {
    border-bottom: 1px solid lightgrey;
}

.optionsContainer {
    height: 100%;
    width: 30%;
    margin-left: 5%;
    border-radius: 6px;
    background: linear-gradient(221deg, #399f67, #437eac);
    text-align: center;
}

#optionsTitle {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    color: white;
    margin-left: 25%;
    width: 50%;
    border-bottom: 1px solid white;
}

.optionsButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.optionsButton {
    background: white;
    color: black;
    border-radius: 4px;
    border: 1px solid lightgrey;
    height: 40px;
    width: 80%;
    margin-left: 10%;
    text-align: center;
    margin-top: 25px;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}
.optionsButton:hover {
    background-color: #008FFF;
    color: white;
    border: 1px solid #008FFF;
}
.optionsButton:active {
    background-color: #008FFF;
    color: white;
    border: 1px solid #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

#topButton {
    margin-top: 5px;
}
