#thanksTitle {
    font-family: 'Inter Tight', sans-serif;
    font-size: 25px;
    font-weight: 300;
    margin-left: 30px;
}

#systemNudgeTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-left: 30px;
}

#systemNudge {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-left: 30px;
    border: 1px solid black;
    border-radius: 5px;
    height: 30px;
    width: 90px;
    background-color: white;
    cursor: pointer;
}
#systemNudge:hover {
    background-color: #09CB62;
}
#systemNudge:active {
    background-color: #008FFF;
}