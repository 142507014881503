.searchMain {
    align-items: left;
    position: absolute;
    height: 900px;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.searchBarContainer {
    width: 60%;
    height: 50px;
    margin-left: 5%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

#searchBar {
    width: 75%;
    height: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #606060;
    color: lightgrey;
    border-radius: 15px;
    background-color: #333333;
    padding-left: 12px;
}

#searchButton {
    width: 14%;
    height: 39px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: lightgrey;
    border-radius: 15px;
    background-color: #333333;
    border: 1px solid #606060;
    margin-left: 8px;
    cursor: pointer;
}
#searchButton:hover {
    border: 1px solid white;
}
#searchButton:active {
    border: 1px solid white; 
    box-shadow: 0px 0px 3px 1.75px white;
}

.resultContainer {
    width: 60%;
    height: 300px;
    background-color: black;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 5%;
    padding-top: 8px;
    border: 1px solid lightgrey;
    color: lightgrey;
}
.resultContainer:hover {
    color: white;
    border: 1px solid white;
}

#textResult {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 17px;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0px;
}