.app {
  background-color: #333333;
  height: 100vh;
  min-height: 1100px;
  width: 81%;
  position: absolute;
  top: 0px;
  left: 19%;
  margin-left: 2px;
  text-align: center;
}

.titleContainer {
  width: 90%;
  margin-left: 4%;
  margin-top: 30px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #333333;
  text-align: left;
}

.welcomeMessage {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 300;
  color: white;
  font-size: 34px;
  margin-top: -60px;
  margin-left: 3%;
}

.bulkContainer {
  width: 90%;
  margin-left: 4%;
  height: 800px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
}

.leftContainer {
  height: 92%;
  width: 49%;
  margin-top: 2%;
  border-radius: 6px;
}

.rightContainer {
  height: 92%;
  width: 46%;
  border-radius: 6px;
  margin-top: 2%;
}

.statsContainer {
  width: 70%;
  height: 60px;
  border-radius: 5px;
  margin-left: 4.5%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.statsDisplay {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  color: lightgrey;
  height: 50px;
  border-left: 1px solid grey;
  width: 33%;
  margin-top: 5px;
  text-align: left;
  padding-left: 7px;
  font-size: 15px;
}

.displaySpan {
  font-size: 17px;
  margin-top: 3px;
  color: white;
}

.toolsContainer {
  height: 330px;
  width: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin-left: -1px;
  margin-top: -0.5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 15px;
  padding-top: 0px;
  margin-top: 30px;
}

.toolTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-align: left;
  width: 100%;
  height: 20px;
}

.toolButton {
  width: 85%;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: white;
  background: linear-gradient(45deg, #09CB62, lightgrey);
  margin-left: 0px;
  margin-top: 6.5px;
  text-align: left;
  padding-left: 10px; 
}

.titleArea {
  text-align: center;
  justify-content: baseline;
  width: 20%;
  height: 6%;
  position: relative;
  left: 39%;
  top: 5px;
  border-bottom: 2px solid black;
}

.title {
  text-align: center;
  font-family: Inter Tight, sans-serif;
  font-weight: 500;
}

.intro {
  width: 70%;
  height: 10%;
  position: relative;
  left: 15%;
  top: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.visualContainer {
  position: absolute;
  top: 37%;
  left: -0.35%;
  height: 450px;
  width: 105%;
  transform: skewY(5.5deg);
  background-color: #0191ff7c;
}
