:root {
    background-color: #333333;
}

html {
    background-color: #333333;
}

.nav {
    width: 20%;
    min-width: 250px;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    border-right: 2px inset black;
    background-color: black;
    margin-right: 2px;
    z-index: 1;
}
  
.navTop {
    justify-content: flex-start;
    width: 90%;
    height: 76px;
    position: relative;
    top: -30px;
    left: 4%;
    background-color: black;
    border-bottom: 1px solid grey;
    display: flex;
}

#logoImage {
    margin-top: 38px;
    margin-left: 10%;
    cursor: pointer;
    filter: invert(1)
}

.headerTop {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: 500;
    margin-top: 37px;
    margin-left: 6px;
    cursor: pointer;
}

.listDiv {
    width: 100%;
    height: 80%;
    overflow-y:auto;
    overflow-x: hidden;
    position: relative;
    top: -30px;
}

.listDiv::-webkit-scrollbar { 
    display: none;
}

.list {
position: relative;
top: 30px;
list-style: none;
}

.home {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.homeTitle {
    font-family: Helvetica, Arial, sans-serif;
    color: black;
}

.formSection {
    font-family: Helvetica, Arial, sans-serif;
    height: 40px;
}

.textSection {
    font-family: Helvetica, Arial, sans-serif;
    height: 40px;
}

.formGen {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formGenTitle {
position: relative;
left: 15px;
font-family: Helvetica, Arial, sans-serif;
color: #3C3C3C;
}

.formExp {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formExpTitle {
    position: relative;
    left: 15px;
    font-family: Helvetica, Arial, sans-serif;
    color: #3C3C3C;
}

.formBank {
    font-family: 'Inter';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formBankTitle {
    position: relative;
    left: 15px;
    font-family: 'Inter';
    color: #3C3C3C;
}

.codeSection {
    font-family: 'Inter';
    height: 40px;
}

.addonsSection {
    font-family: 'Inter';
    height: 40px;
}

.ext {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.extTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.templates {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.templateTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.infoSection {
    font-family: 'Space Grotesk';
    height: 40px;
}

.pricing {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.pricingTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.docs {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.docsTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.accountSpace {
    height: 80px;
    width: 90%;
    position: absolute;
    bottom: 0px;
    left: 5%;
    border-top: 1px solid grey;
    justify-content: center;
    background-color: black;
}

.username {
    position: relative;
    left: 15px;
    top: 10px;
    color: black;
    font-family: 'Space Grotesk';
}