.mainDev {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter Tight', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}