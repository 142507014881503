@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
:root {
  overflow-x: hidden;
  height: 100vh;
}

#root {
  height: 100vh;
}

.router {
  height: 100vh;
  width: 20%;
}



.app {
  background-color: #333333;
  height: 100vh;
  min-height: 1100px;
  width: 81%;
  position: absolute;
  top: 0px;
  left: 19%;
  margin-left: 2px;
  text-align: center;
}

.titleContainer {
  width: 90%;
  margin-left: 4%;
  margin-top: 30px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #333333;
  text-align: left;
}

.welcomeMessage {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 300;
  color: white;
  font-size: 34px;
  margin-top: -60px;
  margin-left: 3%;
}

.bulkContainer {
  width: 90%;
  margin-left: 4%;
  height: 800px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
}

.leftContainer {
  height: 92%;
  width: 49%;
  margin-top: 2%;
  border-radius: 6px;
}

.rightContainer {
  height: 92%;
  width: 46%;
  border-radius: 6px;
  margin-top: 2%;
}

.statsContainer {
  width: 70%;
  height: 60px;
  border-radius: 5px;
  margin-left: 4.5%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.statsDisplay {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  color: lightgrey;
  height: 50px;
  border-left: 1px solid grey;
  width: 33%;
  margin-top: 5px;
  text-align: left;
  padding-left: 7px;
  font-size: 15px;
}

.displaySpan {
  font-size: 17px;
  margin-top: 3px;
  color: white;
}

.toolsContainer {
  height: 330px;
  width: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin-left: -1px;
  margin-top: -0.5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 15px;
  padding-top: 0px;
  margin-top: 30px;
}

.toolTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-align: left;
  width: 100%;
  height: 20px;
}

.toolButton {
  width: 85%;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: white;
  background: linear-gradient(45deg, #09CB62, lightgrey);
  margin-left: 0px;
  margin-top: 6.5px;
  text-align: left;
  padding-left: 10px; 
}

.titleArea {
  text-align: center;
  justify-content: baseline;
  width: 20%;
  height: 6%;
  position: relative;
  left: 39%;
  top: 5px;
  border-bottom: 2px solid black;
}

.title {
  text-align: center;
  font-family: Inter Tight, sans-serif;
  font-weight: 500;
}

.intro {
  width: 70%;
  height: 10%;
  position: relative;
  left: 15%;
  top: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.visualContainer {
  position: absolute;
  top: 37%;
  left: -0.35%;
  height: 450px;
  width: 105%;
  -webkit-transform: skewY(5.5deg);
          transform: skewY(5.5deg);
  background-color: #0191ff7c;
}

#glanceContainer {
    height: 300px;
    width: 400px;
    border-radius: 5px;
    border: 1px solid #333333;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 15px;
    background-color: white;
    margin-bottom: 65px;
    margin-top: 10px;
}

#nameContainer {
    text-align: right;
    width: 97%;
    height: 52px;
    margin-top: 60px;
}

#nameTag {
    color: black;
    margin-top: 25px;
    margin-bottom: 0px;
    font-weight: 500;
}

#requests {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    height: 52px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    color: #333333;
    width: 97%;
}

#requestsTag {
    margin-top: 25px;
    font-weight: 500;
}

#requestsNumber {
    color: black;
    margin-top: 25px;
}

#toProfileButton {
    height: 30px;
    width: 97%;
    margin-left: 0%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    margin-top: 25px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#toProfileButton:hover {
    background-color: #008fff;
    color: white;
}
#toProfileButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
.gettingStartedContainer {
    height: 650px;
    width: 95%;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    padding-left: 35px;
    padding-right: 35px;
    color: white;
    border-left: 1px solid grey;
    padding-top: 20px;
}

#gsTitle {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
}

#gsIntro {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px;
}

#gsPluginContainer {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

#gsExplain {
    width: 75%;
    margin-left: 12.5%;
    font-size: 17px;
    font-weight: 300;
}

#gsButton {
    margin-bottom: 20px;
    height: 30px;
    width: 50%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#gsButton:hover {
    background-color: #008fff;
    color: white;
}
#gsButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

#gsWebContainer {
    border-bottom: 1px solid grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

#gsContactContainer {
    padding-top: 15px;
}

#gsContactEmail {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 20px;
}
.feedbackContainer {
    height: 350px;
    width: 98%;
    border-radius: 7px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 15px;
    background: linear-gradient(33deg, #288150, #3e7cab);
    margin-top: 10px;
    padding-top: 10px;
    color: white;
}

#feedbackTitle {
    font-family: 'Inter Tight', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

#feedbackResponse {
    width: 95%;
}

#feedbackInput {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: 94%;
    padding: 5px;
    background-color: white;
}

#feedbackButton {
    margin-top: 10px;
    height: 30px;
    width: 50%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#feedbackButton:hover {
    background-color: #0be06e;
    color: white;
}
#feedbackButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}


:root {
    background-color: #333333;
}

html {
    background-color: #333333;
}

.nav {
    width: 20%;
    min-width: 250px;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    border-right: 2px inset black;
    background-color: black;
    margin-right: 2px;
    z-index: 1;
}
  
.navTop {
    justify-content: flex-start;
    width: 90%;
    height: 76px;
    position: relative;
    top: -30px;
    left: 4%;
    background-color: black;
    border-bottom: 1px solid grey;
    display: flex;
}

#logoImage {
    margin-top: 38px;
    margin-left: 10%;
    cursor: pointer;
    -webkit-filter: invert(1);
            filter: invert(1)
}

.headerTop {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: 500;
    margin-top: 37px;
    margin-left: 6px;
    cursor: pointer;
}

.listDiv {
    width: 100%;
    height: 80%;
    overflow-y:auto;
    overflow-x: hidden;
    position: relative;
    top: -30px;
}

.listDiv::-webkit-scrollbar { 
    display: none;
}

.list {
position: relative;
top: 30px;
list-style: none;
}

.home {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.homeTitle {
    font-family: Helvetica, Arial, sans-serif;
    color: black;
}

.formSection {
    font-family: Helvetica, Arial, sans-serif;
    height: 40px;
}

.textSection {
    font-family: Helvetica, Arial, sans-serif;
    height: 40px;
}

.formGen {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formGenTitle {
position: relative;
left: 15px;
font-family: Helvetica, Arial, sans-serif;
color: #3C3C3C;
}

.formExp {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formExpTitle {
    position: relative;
    left: 15px;
    font-family: Helvetica, Arial, sans-serif;
    color: #3C3C3C;
}

.formBank {
    font-family: 'Inter';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.formBankTitle {
    position: relative;
    left: 15px;
    font-family: 'Inter';
    color: #3C3C3C;
}

.codeSection {
    font-family: 'Inter';
    height: 40px;
}

.addonsSection {
    font-family: 'Inter';
    height: 40px;
}

.ext {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.extTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.templates {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.templateTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.infoSection {
    font-family: 'Space Grotesk';
    height: 40px;
}

.pricing {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.pricingTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.docs {
    font-family: 'Space Grotesk';
    text-align: left;
    width: 100%;
    height: 60px;
    position: relative;
    top: -20px;
    border-style: none;
    border-bottom-width: 2px;
    border-bottom-color: #3C3C3C;
    border-bottom-style: solid;
    background-color: white;
}

.docsTitle {
    position: relative;
    left: 15px;
    font-family: 'Space Grotesk';
    color: #3C3C3C;
}

.accountSpace {
    height: 80px;
    width: 90%;
    position: absolute;
    bottom: 0px;
    left: 5%;
    border-top: 1px solid grey;
    justify-content: center;
    background-color: black;
}

.username {
    position: relative;
    left: 15px;
    top: 10px;
    color: black;
    font-family: 'Space Grotesk';
}
.formulaToolsMain {
    align-items: left;
    position: fixed;
    height: 100%;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.toolsContainer {
    width: 95%;
    border: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 2.5%;
    flex-wrap: wrap;
}

.toolCardMain {
    height: 200px;
    width: 260px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
    margin: 15px;
    padding-left: 10px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.toolCardMain:hover {
    border: 1px solid #09CB62;
}
.toolCardMain:active {
    box-shadow: 0px 0px 4px 2px #09CB62;
}
.formulaMain {
  align-items: left;
  position: absolute;
  height: 900px;
  width: 81%;
  left: 19%;
  top: 0px;
  margin-left: 2px;
  background-color: #333333;
  color: white;
}

.mainContainer {
  align-items: left;
  text-align: left;
  width: 95%;
  position: absolute;
  left: 5%;
  font-family: 'Inter', sans-serif;
}

.titleSection {
  height: 70px;
  width: 100%;
  position: relative;
  top: 20px;
  justify-content: center;
  text-align: left;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.mainTitle {
  border-bottom: 2px solid lightgrey;
  width: 30%;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.buttonSection {
  align-items: left;
}

.formatTitle {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.excelButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  cursor: pointer;
}
.excelButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF
}
.excelButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.sheetsButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-left: 10px;
  cursor: pointer;
}
.sheetsButton:hover {
  background-color: #008FFF;
  border: 1px solid #008fff;
}
.sheetsButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.inputSection {
  position: relative;
  top: 30px;
}

.inputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainInput {
  height: 125px;
  width: 425px;
  position: relative;
  top: -10px;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  padding: 10px;
  background-color: #333333;
  color: white;
  border-radius: 8px;
}

.submitExplainButtonDiv {
  margin-top: 25px;
}

.submitButton {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: #333333;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  cursor: pointer;
}
.submitButton:hover {
  background-color: #008fff;
  border: 1px solid #008FFF;
}
.submitButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

.outputSection {
  margin-top: 35px;
}

.outputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainExplainOutput {
  height: 150px;
  width: 425px;
  justify-content: top;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background-color: #333333;
  color: white;
  padding: 10px;
}

#copyButton {
  width: 300px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-top: -5px;
  cursor: pointer;
  color: white;
}
#copyButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#copyButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}
.formulaMain {
  align-items: left;
  position: absolute;
  height: 900px;
  width: 81%;
  left: 19%;
  top: 0px;
  margin-left: 2px;
  background-color: #333333;
  color: white;
}

.mainContainer {
  align-items: left;
  text-align: left;
  width: 95%;
  position: absolute;
  left: 5%;
  font-family: 'Inter', sans-serif;
}

.titleSection {
  height: 70px;
  width: 100%;
  position: relative;
  top: 20px;
  justify-content: center;
  text-align: left;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.mainTitle {
  border-bottom: 2px solid lightgrey;
  width: 30%;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.buttonSection {
  align-items: left;
}

.formatTitle {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.excelButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  cursor: pointer;
}
.excelButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF
}
.excelButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.sheetsButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-left: 10px;
  cursor: pointer;
}
.sheetsButton:hover {
  background-color: #008FFF;
  border: 1px solid #008fff;
}
.sheetsButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.inputSection {
  position: relative;
  top: 30px;
}

.inputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainInput {
  height: 125px;
  width: 425px;
  position: relative;
  top: -10px;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  padding: 10px;
  background-color: #333333;
  color: white;
  border-radius: 8px;
}

.submitGenButton {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: #333333;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-top: 25px;
  cursor: pointer;
}
.submitGenButton:hover {
  background-color: #008fff;
  border: 1px solid #008FFF;
}
.submitGenButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

.outputSection {
  margin-top: 35px;
}

.outputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainOutput {
  height: 75px;
  width: 425px;
  justify-content: top;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background-color: #333333;
  color: white;
  padding: 10px;
}

#copyGenButton {
  width: 300px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-top: 5px;
  cursor: pointer;
  color: white;
}
#copyGenButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#copyGenButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}
.mainBank{
    background-color: white;
    height: 100%;
    width: 80%;
    position: fixed;
    top: 0px;
    left: 20%;
    margin-left: 2px;
    font-family: 'Inter';
    text-align: center;
    align-items: center;
}

.titleBank{
    color: 'black';
}
.textToolsMain {
    align-items: left;
    position: fixed;
    height: 100%;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.toolsContainer {
    width: 95%;
    border: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 2.5%;
    flex-wrap: wrap;
}

.toolCardMain {
    height: 200px;
    width: 260px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
    margin: 15px;
    padding-left: 10px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.toolCardMain:hover {
    border: 1px solid #09CB62;
}
.toolCardMain:active {
    box-shadow: 0px 0px 4px 2px #09CB62;
}
.formulaMain {
    align-items: left;
    position: absolute;
    height: 900px;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
  }
  
  .mainContainer {
    align-items: left;
    text-align: left;
    width: 95%;
    position: absolute;
    left: 5%;
    font-family: 'Inter', sans-serif;
  }
  
  .titleSection {
    height: 70px;
    width: 100%;
    position: relative;
    top: 20px;
    justify-content: center;
    text-align: left;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
  }
  
  .mainTitle {
    border-bottom: 2px solid lightgrey;
    width: 30%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
  }
  
  .buttonSection {
    align-items: left;
  }
  
  .formatTitle {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
  
  .excelButton {
    width: 150px;
    height: 50px;
    position: relative;
    top: -10px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    color: white;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    cursor: pointer;
  }
  .excelButton:hover {
    background-color: #008FFF;
    border: 1px solid #008FFF
  }
  .excelButton:active {
    background-color: #008FFF;
    border: 1px solid lightgrey;
  }
  
  .sheetsButton {
    width: 150px;
    height: 50px;
    position: relative;
    top: -10px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    color: white;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
  }
  .sheetsButton:hover {
    background-color: #008FFF;
    border: 1px solid #008fff;
  }
  .sheetsButton:active {
    background-color: #008FFF;
    border: 1px solid lightgrey;
  }
  
  .inputSection {
    position: relative;
    top: 30px;
  }
  
  .inputLabel {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
  
  .mainInput {
    height: 125px;
    width: 425px;
    position: relative;
    top: -10px;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    border: 1px solid lightgrey;
    padding: 10px;
    background-color: #333333;
    color: white;
    border-radius: 8px;
  }
  
  .submitSolverButton {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    background-color: #333333;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    cursor: pointer;
    margin-top: 25px;
  }
  .submitSolverButton:hover {
    background-color: #008fff;
    border: 1px solid #008FFF;
  }
  .submitSolverButton:active {
    background-color: #09CB62;
    border: 1px solid #09CB62;
  }
  
  .outputSection {
    margin-top: 35px;
  }
  
  .outputLabel {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
  
  .mainSolverOutput {
    height: 125px;
    width: 425px;
    justify-content: top;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background-color: #333333;
    color: white;
    padding: 10px;
  }
.pdfMain {
  align-items: left;
  position: fixed;
  height: 100%;
  width: 81%;
  left: 19%;
  top: 0px;
  margin-left: 2px;
  background-color: #333333;
  color: white;
  padding-left: 50px;
  font-size: 17px;
}

#pdfMainTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 40px;
  width: 45%;
  min-width: 250px;
  border-bottom: 1px solid white;
}

#pdfStepOne {
  font-family: 'Inter', san-serif;
  font-weight: 500;
  font-size: 17px;
}

#fileInput {
  margin-top: -10px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
}

#fileInput::file-selector-button {
  background-color: #333333;
  height: 40px;
  width: 150px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
#fileInput::file-selector-button:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#fileInput::file-selector-button:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

#pdfStepTwo {
  font-family: 'Inter', san-serif;
  font-weight: 500;
  font-size: 17px;
  margin-top: 40px;
}

#textInput {
  margin-top: -10px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 10px;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
}

#pdfSubmitButton {
  margin-top: 5px;
  height: 35px;
  width: 150px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 19px;
  background-color: #333333;
  border: 1px solid lightgrey;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
#pdfSubmitButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#pdfSubmitButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

#pdfAnswerTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 22px;
  margin-top: 40px;
}

#pdfAnswerText {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  width: 50%;
}
#mainExt {
  background-color: #333333;
  height: 100%;
  width: 81%;
  position: fixed;
  top: 0px;
  left: 19%;
  margin-left: 2px;
  text-align: center;
  color: white;
}

.extensionsDiv {
  width: 100%;
  margin-top: 5%;
  margin-left: 2%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.excelSection {
  width: 45%;
  margin-left: 4%;
  margin-right: 1%;
}

.excelTitle {
  width: 85%;
  border-bottom: 2px solid lightgrey;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding-left: 5px;
}

.excelDes {
  width: 90%;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19.5px;
}

.instructionsList {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

#toExcelStore {
  height: 85px;
  width: 193px;
  padding-top: 5px;
  background-color: #0be06e;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 20%;
}
#toExcelStore:hover {
  background-color: #008fff;
  color: white;
}
#toExcelStore:active {
  background-color: #008fff;
  color: white;
  box-shadow: 0px 0px 4px 2px #00B4FF;
}

#pluginGettingStarted {
  width: 42%;
  border-radius: 6px;
  background: linear-gradient(125deg, #29a55b, #3e7cab);
  padding-left: 30px;
}
.mainTemp {
    background-color: white;
    height: 100%;
    width: 80%;
    position: fixed;
    top: 0px;
    left: 20%;
    margin-left: 2px;
    font-family: 'Inter';
    text-align: center;
    align-items: center;
}

.desTemp {
    width: 95%;
    position: relative;
    left: 2.5%;
}
.mainPricing {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}

.pricingTop {
    position: absolute;
    width: 30%;
    left: 35%;
    font-weight: 500;
    height: 50;
    border-bottom: 2px solid lightgrey;
}

.pricingChart {
    width: 100%;
    text-align: center;
    justify-content: center;
    position: relative;
    top: -5%;
}

#divBackingMiddle {
    background: linear-gradient(33deg, #288150, #3e7cab);
}

#prodCurrent {
    border: none;
    color: black;
    background: white;
    border-radius: 5px;
    width: 70%;
    height: 60px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 25px;
    position: relative;
    top: -20px;
}

.pricingDashButton {
    border: 1px solid white;
    color: white;
    background: #333333;
    border-radius: 5px;
    width: 70%;
    height: 60px;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    margin-top: -20px;
}
.pricingDashButton:hover {
    background-color: #09CB62;
    color: white;
}
.pricingDashButton:active {
    background-color: #008FFF;
    color: white;
    border: none;
}
.mainDocs {
    background-color: #333333;
    height: 5000px;
    width: 81%;
    position: absolute;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-align: center;
    color: white;
}

.fgDiv {
    position: relative;
    width: 90%;
    left: 5%;
    text-align: left;
    align-items: left;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.introTitle {
    height: 25px;
    width: 100%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.subTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.introMain {
    align-self: left;
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsTitle {
    height: 25px;
    width: 100%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.basicsa {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsb {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.basicsc {
    position: relative;
    top: 0px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.feDiv {
    position: relative;
    width: 90%;
    left: 5%;
    text-align: left;
    align-items: left;
    border-bottom: 2px solid black;
}

.titleSpan {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 300;
}

.contentDiv {
    width: 94%;
    margin-left: 3%;
}

.exampleText {
    text-align: center;
    width: 90%;
    font-family: "Inter Tight", sans-serif;
    font-weight: 300;
}

#docsLink {
    color: #09CB62;
    text-decoration: none;
}
#docsLink:hover {
    color: #008FFF;
    text-decoration: underline;
}
#docsLink:active {
    color: #008FFF;
    -webkit-text-decoration: wavy;
            text-decoration: wavy;
}
.mainAcc {
    background-color: #333333;
    height: 150%;
    width: 81%;
    position: absolute;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
}

.topContainer {
    height: 150px;
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: flex-start;
    border-radius: 6px;
    margin-top: 40px;
}

.profileContainer {
    width: 70%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(237deg, #288150, #3e7cab);
    display: flex;
    justify-content: flex-start;
}

#profileInfo {
    margin-left: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: white;
    text-align: left;
}

#profileName {
    font-size: 35px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    height: 35px;
    margin-top: 15px;
}

#profileEmail {
    margin-top: -25px;
    font-size: 20px;
}

.dateContainer {
    width: 20%;
    margin-left: 5%;
    height: 60%;
    margin-top: 22.5px;
    border-right: 1px solid lightgrey;
    text-align: right;
}

#dateTitle {
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: lightgrey;
    margin-top: 10px;
    height: 15px;
    margin-right: 12px;
}

#dateMessage {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    color: white;
    margin-top: 0px;
    margin-right: 12px;
}

.middleContainer {
    display: flex;
    justify-content: flex-start;
    height: 500px;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    border-radius: 6px;
}

.infoContainer {
    width: 60%;
    height: 100%;
    color: white;
    text-align: left;
}

#infoTitle {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    margin-left: 0px;
}

.infoGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: lightgrey;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    border-top: 1px solid lightgrey;
}

#infoSpan {
    font-size: 22px;
    color: white;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
}

#bottomSpan {
    border-bottom: 1px solid lightgrey;
}

.optionsContainer {
    height: 100%;
    width: 30%;
    margin-left: 5%;
    border-radius: 6px;
    background: linear-gradient(221deg, #399f67, #437eac);
    text-align: center;
}

#optionsTitle {
    font-size: 24px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    color: white;
    margin-left: 25%;
    width: 50%;
    border-bottom: 1px solid white;
}

.optionsButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.optionsButton {
    background: white;
    color: black;
    border-radius: 4px;
    border: 1px solid lightgrey;
    height: 40px;
    width: 80%;
    margin-left: 10%;
    text-align: center;
    margin-top: 25px;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}
.optionsButton:hover {
    background-color: #008FFF;
    color: white;
    border: 1px solid #008FFF;
}
.optionsButton:active {
    background-color: #008FFF;
    color: white;
    border: 1px solid #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

#topButton {
    margin-top: 5px;
}

#signUp {
    width: 120px;
    height: 35px;
    position: relative;
    top: -5px;
    background-color: #0be06e;
    border: 2px solid #0be06e;
    text-align: center;
    border-radius: 6px;
    font-weight: 300;
    letter-spacing: 1px;
}
#signUp:hover {
    background-color: #008FFF;
    border: 2px solid #008FFF;
}
#signUp:hover h3 {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUp:active{
    background-color: #008fff;
    border: 1px solid #008fff;
    box-shadow: 0.5px 0.5px 4px 2px #00B4FF;
}
#signUp:active h3 {
    -webkit-filter: invert(0);
            filter: invert(0);
}

#userGroup {
    display: flex;
    justify-content: flex-start;
    margin-top: 33px;
    margin-right: 25px;
}



#signUp:active {
    background-color: #008FFF;
}




#signUp:active {
    background-color: #008FFF;
}
.hello{
    text-align: left;
}
.header{
    margin: auto; 
}
.lock-scroll{
overflow: hidden;
}







.homeContainer {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.list-wrapper::-webkit-scrollbar {
    display: none;
}

#signUpToday {
    border: none;
    color: black;
    background-color: #0be06e;
    border-radius: 8px;
    width: 195px;
    height: 55px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 22px;
    cursor: pointer;
}

#signUpToday:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpToday:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpToday:hover img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpToday:hover h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpToday:active img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpToday:active h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus::placeholder {
    color: transparent;
}

#styledDiv {
    background-color: #46eb7d;
    -webkit-transform: skewY(-5.5deg);
            transform: skewY(-5.5deg);
    position: relative;
    top: 50px;
    width: 102%;
    left: -1%;
    height: 525px;
}

#subFrontDiv {
    width: 102%;
    height: 600px;
    position: relative;
    top: -45px;
    left: -1%;
    text-align: center;
    -webkit-transform: skewY(5.5deg);
            transform: skewY(5.5deg);
}

#subHeaderOne {
    color: black;
    position: relative;
    top: 105px;
    left: 24%;
    font-size: 35px;
}

#visualsOne {
    height: 380px;
    width: 555px;
    background-color: black;
    position: relative;
    top: 27.5px;
    left: 0%;
    border-radius: 7px;
}

#visualsTwo {
    height: 90px;
    width: 380px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    top: -42.5px;
    left: 40%;
    justify-content: center;
    display: flex;
    color: black;
}

#subTextDes {
    width: 35%;
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 14%;
    margin-top: 1.5%;
}

#thirdFrontDiv {
    height: 300px;
    width: 100%;
}
.homeContainer{
    max-width: 1920px;
    margin: auto;
}

.pricingFrontContainer {
    margin-top: 150px;
    height: 100%;
}

.pricingMainContent {
    display: flex;
    width: 84%;
    min-width: 410px;
    flex-wrap: wrap;
    margin-left: 8%;
    justify-content: space-evenly;
    border: 1px solid #333333;
    border-radius: 10px;
    background-color: #333333;
    margin-bottom: 75px;
}

.pricingTopText {
    width: 84%;
    margin-left: 8%;
}

#pricingSubTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 35px;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#titleSpanPricing {
    color: #09CB62;
}

.corpPricingInfo {
    background-color: #333333;
    border-radius: 10px;
    border: 1px solid #333333;
    width: 84%;
    min-width: 410px;
    margin-left: 8%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 25px;
}

#corpTextContainer {
    width: 50%;
    min-width: 360px;
}

#corpHeader {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin-left: 25px;
}

#corpDescription {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-left: 25px;
}

#corpButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#corpContact {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 30%;
    min-width: 360px;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#corpContact:hover {
    background-color: #09CB62;
    color: white;
}
#corpContact:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}

.recContainer {
    display: flex;
    justify-content: flex-start;
    height: 50px;
}

#recSpan {
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: white;
    margin-top: 23px;
    border-radius: 10px;
    margin-left: 10px;
    height: 23px;
    background-color: #09CB62;
    padding-left: 7px;
    padding-right: 7px;
}

.prodContainer {
    height: 365px;
    width: 350px;
    border: 1px solid grey;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding-top: 10px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}
.prodContainer:hover {
    border: 1px solid white;
    box-shadow: 0px 0px 2px 0.5px white;
}

.prodTitle {
    width: 10%;
    margin-left: 10%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin-top: 0.5em;
}

#prodTitlePop {
    
    margin-left: 10%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin-top: 0.5em;
}

.monthlySpan {
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    margin-left: 6px;
    font-size: 14px;
}

.frontPurchase {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-top: -10px;
}
.frontPurchase:hover {
    background-color: #09CB62;
    color: white;
}
.frontPurchase:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}
.blogMainContent {
    height: 100vh;
    width: 100%;
}

.blogMap {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.blogCardDiv {
    height: 300px;
    width: 300px;
    background: white;
    font-family: 'Inter', sans-serif;
    border: 1px solid black;
    border-radius: 10px;
    margin: 25px;
    padding: 10px;
}

#blogCardImg {
    width: 80%;
    height: 40%;
    margin-left: 10%;
    border-radius: 8px;
    margin-top: 10%;
    margin-bottom: 5%;
}


.aboutContent {
    width: 100%;
    height: 110vh;
    margin-top: 150px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

#aboutGraphicContent {
    margin-left: 5%;
    width: 25%;
    margin-top: 10px;
}

#aboutTextContent {
    width: 60%;
    margin-left: 10%;
}

#aboutMainHeader {
    border-bottom: 1px solid black;
    width: 92%;
    margin-bottom: 30px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    padding-left: 2%;
}

#aboutMainText {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    width: 89%;
    padding-left: 2%;
}

#aboutEndingDiv {
    height: 0px;
    width: 92%;
    border-top: 0.5px solid black;
    margin-top: 32px;
    margin-bottom: 10%;
    padding-left: 2%;
}

.aboutInfoSection {
    width: 102%;
    height: 250px;
    position: absolute;
    top: 105vh;
    left: -1%;
    color: white;
    background-color: #1F1F1F;
}
:root {
    width: 102%;
}

.mainLanding {
    width: 104%;
    margin-left: -2%;
    height: 99vh;
    overflow: hidden;
    position: fixed;
}
.main::-webkit-scrollbar {
    display: none;
}

.backgroundDiv {
    background-color: #008FFF;
    -webkit-transform: skewY(-7deg);
            transform: skewY(-7deg);
    height: 60vh;
    margin-top: 75vh;
    width: 106%;
    margin-left: -3%;
}

.mainFlowDiv {
    position: absolute;
    top: 18%;
    left: 7%;
    height: 70vh;
    width: 88%;
    display: flex;
    justify-content: space-between;
}

.textDiv {
    height: 96%;
    width: 44%;
    padding-left: 20px;
    padding-top: 15px;
    border-radius: 12px;
    background-color: white;
    color: black;
    box-shadow: 1px 2px 5px 1px rgb(87, 89, 98);
}

.mediaDiv {
    height: 100%;
    width: 45%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#mainLandingTitle {
    font-family: 'Inter', sans-serif;
    font-weight: bolder;
    font-size: 38px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#titleSpanLanding {
    background: linear-gradient(66deg, rgb(204, 53, 204), #079ade);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

#mainLandingSubTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 23px;
    margin-top: 20px;
    width: 87%;
}

#featuresLandingList {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#signUpTodayLanding {
    border: none;
    color: black;
    background-color: #0be06e;
    border-radius: 8px;
    width: 340px;
    height: 55px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 5px;
    cursor: pointer;
}

#signUpTodayLanding:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpTodayLanding:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpTodayLanding:hover img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodayLanding:hover h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodayLanding:active img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodayLanding:active h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}

#trustedDiv {
    width: 100%;
    height: 120px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 1px 2px 5px 1px rgb(87, 89, 98);
}
:root {
    background-color: white;
}

#accountCreationDiv {
    height: 99vh;
    padding: 0px;
    position: fixed;
}

#accountCreationHeader {
    position: relative;
    top: 10px;
    left: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

#headerContainer {
    display: flex;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0%;
    margin-left: 4.25%;
    margin-top: 24px;
    border-bottom: 1px solid #c7c7c7;
    width: 400px;
    padding-bottom: 6px;
}
#siteLogoCreate {
    cursor: pointer;
}
#siteTitle {
    margin-left: 8px;
    margin-top: 7px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}

#toSignIn {
    text-decoration: none;
    color: grey;
}

#googleAuthDiv2 {
    border: none;
    background-color: white;
    position: relative;
    margin-top: 40px;
    left: 0%;
    width: 380px;
    height: 500px;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mainGroup {
    width: 100%;
    margin-left: 37.5%;
    margin-top: 0px;
}

#groupContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    margin-top: 0px;
}

#nameCreate {
    color: #333333;
    margin-bottom: 2px;
    font-size: 16.5px;
    font-weight: 400;
}

#name {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#name::-webkit-input-placeholder{
    font-size: smaller;
}
#name::placeholder{
    font-size: smaller;
}
#name:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#name:hover {
    border: 2px solid #09CB62;
    height: 35px;
    /* background-color: rgb(248, 248, 248); */
}

#emailTitle {
    color: #333333;
    margin-bottom: 3px;
    font-size: 16.5px;
    font-weight: 400;
}

#email {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#email::-webkit-input-placeholder{
    font-size: smaller;
}
#email::placeholder{
    font-size: smaller;
}
#email:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#email:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 35px;
}

#passTitle {
    color: #333333;
    margin-bottom: 3px;
    font-size: 16.5px;
    font-weight: 400;
}

#pass {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#pass::-webkit-input-placeholder{
    font-size: smaller;
}
#pass::placeholder{
    font-size: smaller;
}
#pass:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#pass:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 35px;
}

#termsGroup {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    margin-top: 5px;
}
#terms {
    margin-right: 5px;
}
#termsSpan {
    color: #008fff;
    cursor: pointer;
}
#termsSpan:hover {
    text-decoration: underline;
}
#termSpan:active {
    text-decoration: underline;
    color: #09CB62;
}

#buttonInCreate {
    position: relative;
    top: 20px;
    width: 77.5%;
    height: 42px;
    border-radius: 5px;
    border: 1.5px solid #09CB62;
    background-color: #09CB62;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: white;
    cursor: pointer;
}
#buttonInCreate:hover {
    background-color: #008FFF;
    color: white;
}
#buttonInCreate:active {
    background-color: #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
    color: white;
    border: none;
}

#imgContainer {
    position: fixed;
    top: 0%;
    left: 550px;
    width: 74.5%;
    padding-top: 2%;
    padding-left: 2%;
    border-left: 1px solid black;
    background-color: #5fb991;
    height: 99vh;
}
#slogan {
    font-family: 'Inter', sans-serif;
    margin-top: -60px;
}
#imgBig {
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 25px;
}


#preSwitch {
    text-decoration: none;
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
}

#personalCheckoutDiv {
    font-family: 'Inter';
    height: 800;
}
.FormGroup {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #ffffff;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #4ad388;
	border-radius: 4px;
}

.FormRow {
	display: flex;
	align-items: center;
	margin-left: 15px;
	
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

#discountCodeContainer {
	display: flex;
	margin: 0px 15px 5px 15px;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 12px;
	height: 45px;
	background-color: #ffffff;
	border-radius: 4px;
}

#discountCodeTitle {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 17px;
	color: black;
	margin-right: 40%;
	margin-top: 5px;
	height: 30px;
	justify-content: center;
	width: 30%;
	text-align: left;
}

#codeInput {
	color: black;
	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	height: 25px;
	width: 30%;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 16px;
	margin-top: 2.5px;
}

.buyButton {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 30px 15px 15px 15px;
	background-color: #008FFF;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
	font-size: 19px;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}
.buyButton:hover {
	background-color: #09CB62;
}
.buyButton:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	-webkit-transform: scale(0.99);
	        transform: scale(0.99);
}

.App {
	margin-left: 25%;
	margin-top: 5%;
	margin-right: 25%;
}
#thanksTitle {
    font-family: 'Inter Tight', sans-serif;
    font-size: 25px;
    font-weight: 300;
    margin-left: 30px;
}

#systemNudgeTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-left: 30px;
}

#systemNudge {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-left: 30px;
    border: 1px solid black;
    border-radius: 5px;
    height: 30px;
    width: 90px;
    background-color: white;
    cursor: pointer;
}
#systemNudge:hover {
    background-color: #09CB62;
}
#systemNudge:active {
    background-color: #008FFF;
}
:root {
    height: 100%;
}

.signInDiv {
    padding: 0px;
    font-family: 'Inter', 'sans serif';

}

.googleAuthDiv1 {
    border-radius: 6px;
    background-color: white;
    position: relative;
    margin: auto;
    width: 26%;
    height: 300px;
    padding-bottom: 45px;
    font-family: 'Inter';
    margin-top: 5%;
    text-align: center;
}

.signInTitle {
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    text-align: left;
}

.emailTitle {
    color: #333333;
    font-size: 16.5px;
    text-align: left;
    font-weight: 400;
}

.email {
    background-color: rgb(252, 252, 252);
    width: 100%;
    height: 45px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', 'sans serif';
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
.email::-webkit-input-placeholder{
    font-size: medium;
}
.email::placeholder{
    font-size: medium;
}
.email:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
.email:hover {
    border: 2px solid #09CB62;
    height: 45px;
}

.passTitle {
    color: #333333;
    font-size: 16.5px;
    text-align: left;
    font-weight: 400;
}

#signInPass {
    background-color: rgb(252, 252, 252);
    width: 100%;
    height: 45px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter';
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#signInPass::-webkit-input-placeholder{
    font-size: medium;
}
#signInPass::placeholder{
    font-size: medium;
}
#signInPass:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#signInPass:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 45px;
}

.buttonInSign {
    position: relative;
    top: 5px;
    width: 82.5%;
    height: 45px;
    border-radius: 7px;
    border: 1.5px solid #09CB62;
    background-color: #09CB62;
    font-family: 'Inter';
    font-size: 19px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-left: 8px;
}
.buttonInSign:hover {
    background-color: #008FFF;
    color: white;
}
.buttonInSign:active {
    background-color: #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
    color: white;
    border: none;
}
.headerContainer {
    display: flex;
    justify-content: flex-start;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 0%;
    margin-left: 2%;
    margin-top: 24px;
    width: 400px;
    padding-bottom: 6px;
    width: 98%;
}
.siteLogoCreate {
    cursor: pointer;
}
.siteTitle {
    margin-left: 8px;
    margin-top: 7px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}
.preSwitch{
    font-family: 'Inter', 'sans serif';
}
.learnMore{
    text-align: right;
    font-family: 'Inter','sans serif';
}

#passResetLink {
    width: 100%;
    text-align: right;
    margin-left: 2.5%;
    color: grey;
    cursor: pointer;
}
#passResetLink:hover {
    text-decoration: underline;
    color: black;
}
#passResetLink:active {
    text-decoration: underline;
    color: #008FFF;
}

:root {
    width: 100%;
    
}

.productPurchase {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 80%;
    height: 60px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 25px;
    margin-top: -20px;
}
.productPurchase:hover {
    background-color: #09CB62;
    color: white;
}
.productPurchase:active {
    background-color: #008FFF;
    color: white;
    border: none;
}

.pricingFrontContainer {
    margin-top: 150px;
    height: 100%;
}

.pricingWallMainContent {
    display: flex;
    width: 84%;
    min-width: 410px;
    flex-wrap: wrap;
    margin-left: 8%;
    justify-content: space-evenly;
    border: 1px solid #333333;
    border-radius: 10px;
    background-color: #333333;
    margin-bottom: 75px;
    margin-top: 65px;
}
.pricingWallMainContent:hover {
    box-shadow: 0px 0px 10px 1px #09CB62;
}

.pricingTopText {
    width: 84%;
    margin-left: 8%;
}

#pricingSubTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 35px;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#titleSpanPricing {
    color: #09CB62;
}

.aboutPricingInfo {
    background-color: #333333;
    border-radius: 10px;
    border: 1px solid #333333;
    width: 84%;
    min-width: 410px;
    margin-left: 8%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 25px;
}

#aboutTextContainer {
    width: 96%;
    min-width: 360px;
    margin-left: 2%;
    margin-bottom: 15px;
}

#aboutPricingHeader {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin-left: 25px;
}

#aboutPricingDescription {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-left: 25px;
}

#corpButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#corpContact {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 30%;
    min-width: 360px;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#corpContact:hover {
    background-color: #09CB62;
    color: white;
}
#corpContact:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}

.prodContainer {
    height: 365px;
    width: 350px;
    border: 1px solid grey;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding-top: 10px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}
.prodContainer:hover {
    border: 1px solid white;
    box-shadow: 0px 0px 2px 0.5px white;
}

.prodTitle {
    width: 80%;
    margin-left: 10%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin-top: 0.5em;
}

.monthlySpan {
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    margin-left: 6px;
    font-size: 14px;
}

.frontPurchase {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-top: -10px;
}
.frontPurchase:hover {
    background-color: #09CB62;
    color: white;
}
.frontPurchase:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}
.mainDev {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter Tight', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}
#solutionsRoot {
    background-color: black;
    width: 102%;
    margin-left: -1%;
}

#solutionsHeadContainer {
    width: 100%;
    height: 500px;
    margin-top: 100px;
    background-color: #09CB62;
    color: black;
    margin-bottom: 0%;
    display: flex;
    justify-content: space-between;
}

#solutionsHeadText {
    margin-left: 17.5%;
    width: 25%;
    margin-top: 75px;
}

#solutionsSection{
    font-family: Inter, sans-serif;
    font-size: 19px;
    letter-spacing: 1px;
}

#solutionsTitle {
    font-size: 35px;
    font-weight: 600;
    font-family: Inter Tight, sans-serif;
    color: white;
    letter-spacing: 1px;
    line-height: 45px;
    margin-top: 25px;
}

#signUpTodaySolutions {
    border: none;
    color: black;
    background-color: white;
    border-radius: 8px;
    width: 195px;
    height: 55px;
    font-size: 23px;
    font-family: Helvetica, Arial, sans-serif;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
#signUpTodaySolutions:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpTodaySolutions:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpTodaySolutions:hover img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodaySolutions:hover h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodaySolutions:active img {
    -webkit-filter: invert(1);
            filter: invert(1);
}
#signUpTodaySolutions:active h5 {
    -webkit-filter: invert(1);
            filter: invert(1);
}

#solutionsHeadGraphic {
    width: 40%;
    margin-right: 10%;
}

#solutionsMainContainer {
    background-color: black;
    width: 100%;
    margin-top: -1.5%;
}

#solutionsBufferZone {
    height: 10px;
    width: 100%;
    margin-top: 0%;
}

#cardSectionSolutions {
    margin-bottom: 55px;
}

.cardHolder {
    display: flex;
    justify-content: space-between;
    color: white;
    width: 100%;
    height: 160px;
    border-top: 1px solid white;
    margin-bottom: 15px;
}

#cardHolderLast {
    border-bottom: 1px solid white;
}

#industryRight {
    font-size: 23px;
    font-weight: 500;
    font-family: Inter, sans-serif;
    text-align: left;
    width: 12%;
    border-right: 1px solid white;
    margin-top: 15px;
    margin-left: 1.5%;
}

#solutionsRight {
    font-size: 21px;
    font-weight: 300;
    font-family: Inter, sans-serif;
    text-align: right;
    width: 45%;
    margin-right: 2.5%;
}

#industryRightText {
    margin-top: 0%;
}

#solutionsBottomCap {
    width: 100%;
    height: 5px;
    border-top: 1px solid white;
}
.mainUpgrade {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}


#upgradeButton {
    border: none;
    color: black;
    background: white;
    border-radius: 5px;
    width: 105px;
    height: 35px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    position: relative;
    top: -20px;
    margin: 10px;
    cursor: pointer;
}

#upgradeButton:hover {
    background-color: #09CB62;
    color: white;
}

#upgradeButton:active {
    background-color: #008FFF;
    color: white;
    border: none;
}
.mainMobileContainer {
    background: linear-gradient(0deg, #288150, #11ccd6, #9a32d6, #3e7cab);
    height: 4900px;
    width: 105%;
    margin-left: -2.5%; 
    padding-top: 50px;
    margin-top: -10px;
}

.list-wrapper::-webkit-scrollbar {
    display: none;
}

#desktopButton {
    border: none;
    color: black;
    background-color: #0be06e !important;
    border-radius: 8px;
    width: 90%;
    height: 45px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 22px;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
}
#desktopButton:hover{
    background-color: #008fff !important;
    color: white !important;
}
#desktopButton:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus::placeholder {
    color: transparent;
}

#styledDiv {
    background-color: #46eb7d;
    -webkit-transform: skewY(-5.5deg);
            transform: skewY(-5.5deg);
    position: relative;
    top: 50px;
    width: 102%;
    left: -1%;
    height: 525px;
}

#subFrontDiv {
    width: 102%;
    height: 600px;
    position: relative;
    top: -45px;
    left: -1%;
    text-align: center;
    -webkit-transform: skewY(5.5deg);
            transform: skewY(5.5deg);
}

#subHeaderOne {
    color: black;
    position: relative;
    top: 105px;
    left: 24%;
    font-size: 35px;
}

#visualsOne {
    height: 380px;
    width: 555px;
    background-color: black;
    position: relative;
    top: 27.5px;
    left: 0%;
    border-radius: 7px;
}

#visualsTwo {
    height: 90px;
    width: 380px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    top: -42.5px;
    left: 40%;
    justify-content: center;
    display: flex;
    color: black;
}

#subTextDes {
    width: 35%;
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 14%;
    margin-top: 1.5%;
}

#thirdFrontDiv {
    height: 300px;
    width: 100%;
}

#emailCollectionContainer {
    width: 92.5%;
    height: 350px;
    text-align: left;
    padding-left: 15px;
    background-color: white;
    padding-top: 2px;
    margin-left: 2%;
    border-radius: 10px;
}

.mobileTextTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 21px;
    width: 95%;
}

.mobileText {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    width: 95%;
}

#emailCollection {
    width: 90%;
    border: 1px solid grey;
    border-radius: 8px;
    height: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 10px;
}

#emailSubmit {
    width: 95%;
    height: 45px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    background-color: #008FFF;
    color: white;
    margin-top: 10px;
}

.mobileFeatures {
    width: 92.5%;
    margin-left: 2%;
    margin-top: 40px;
}

#mobileFeaturesTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: white;
    margin-left: 5px;
    width: 100%;
    border-bottom: 1px solid white;
}

.featureCardMobile {
    background-color: white;
    border: none;
    border-radius: 10px;
    color: black;
    font-family: "Inter", sans-serif;
    margin-top: 15px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
}

#featureCardTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 0px;
}

#featureCardText {
    font-weight: 300;
    font-size: 17px;
    margin-top: 10px;
}



.searchMain {
    align-items: left;
    position: absolute;
    height: 900px;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.searchBarContainer {
    width: 60%;
    height: 50px;
    margin-left: 5%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

#searchBar {
    width: 75%;
    height: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #606060;
    color: lightgrey;
    border-radius: 15px;
    background-color: #333333;
    padding-left: 12px;
}

#searchButton {
    width: 14%;
    height: 39px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: lightgrey;
    border-radius: 15px;
    background-color: #333333;
    border: 1px solid #606060;
    margin-left: 8px;
    cursor: pointer;
}
#searchButton:hover {
    border: 1px solid white;
}
#searchButton:active {
    border: 1px solid white; 
    box-shadow: 0px 0px 3px 1.75px white;
}

.resultContainer {
    width: 60%;
    height: 300px;
    background-color: black;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 5%;
    padding-top: 8px;
    border: 1px solid lightgrey;
    color: lightgrey;
}
.resultContainer:hover {
    color: white;
    border: 1px solid white;
}

#textResult {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 17px;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0px;
}


.formulaMain {
    align-items: left;
    position: absolute;
    height: 900px;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.mainContainer {
    align-items: left;
    text-align: left;
    width: 95%;
    position: absolute;
    left: 5%;
    font-family: 'Inter', sans-serif;
}

.titleSection {
    height: 70px;
    width: 100%;
    position: relative;
    top: 20px;
    justify-content: center;
    text-align: left;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.mainTitle {
    border-bottom: 2px solid lightgrey;
    width: 30%;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
}

.buttonSection {
    align-items: left;
}

.formatTitle {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

    .excelButton {
    width: 150px;
    height: 50px;
    position: relative;
    top: -10px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    color: white;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    cursor: pointer;
    }
    .excelButton:hover {
    background-color: #008FFF;
    border: 1px solid #008FFF
    }
    .excelButton:active {
    background-color: #008FFF;
    border: 1px solid lightgrey;
    }

    .sheetsButton {
    width: 150px;
    height: 50px;
    position: relative;
    top: -10px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    color: white;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
    }
    .sheetsButton:hover {
    background-color: #008FFF;
    border: 1px solid #008fff;
    }
    .sheetsButton:active {
    background-color: #008FFF;
    border: 1px solid lightgrey;
    }

    .inputSection {
    position: relative;
    top: 30px;
    }

    .inputLabel {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    }

    .mainInput {
    height: 125px;
    width: 425px;
    position: relative;
    top: -10px;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    border: 1px solid lightgrey;
    padding: 10px;
    background-color: #333333;
    color: white;
    border-radius: 8px;
    }

    .submitExplainButtonDiv {
    margin-top: 25px;
    }

    .submitButton {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    background-color: #333333;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    cursor: pointer;
    }
    .submitButton:hover {
    background-color: #008fff;
    border: 1px solid #008FFF;
    }
    .submitButton:active {
    background-color: #09CB62;
    border: 1px solid #09CB62;
    }

    .outputSection {
    margin-top: 35px;
    }

    .outputLabel {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    }

    .mainExplainOutput {
    height: 150px;
    width: 425px;
    justify-content: top;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background-color: #333333;
    color: white;
    padding: 10px;
    }

    #copyButton {
    width: 300px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid lightgrey;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    margin-top: -5px;
    cursor: pointer;
    color: white;
    }
    #copyButton:hover {
    background-color: #008FFF;
    border: 1px solid #008FFF;
    }
    #copyButton:active {
    background-color: #09CB62;
    border: 1px solid #09CB62;
    }

