@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
:root {
    background-color: white;
}

#accountCreationDiv {
    height: 99vh;
    padding: 0px;
    position: fixed;
}

#accountCreationHeader {
    position: relative;
    top: 10px;
    left: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

#headerContainer {
    display: flex;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0%;
    margin-left: 4.25%;
    margin-top: 24px;
    border-bottom: 1px solid #c7c7c7;
    width: 400px;
    padding-bottom: 6px;
}
#siteLogoCreate {
    cursor: pointer;
}
#siteTitle {
    margin-left: 8px;
    margin-top: 7px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}

#toSignIn {
    text-decoration: none;
    color: grey;
}

#googleAuthDiv2 {
    border: none;
    background-color: white;
    position: relative;
    margin-top: 40px;
    left: 0%;
    width: 380px;
    height: 500px;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mainGroup {
    width: 100%;
    margin-left: 37.5%;
    margin-top: 0px;
}

#groupContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    margin-top: 0px;
}

#nameCreate {
    color: #333333;
    margin-bottom: 2px;
    font-size: 16.5px;
    font-weight: 400;
}

#name {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#name::placeholder{
    font-size: smaller;
}
#name:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#name:hover {
    border: 2px solid #09CB62;
    height: 35px;
    /* background-color: rgb(248, 248, 248); */
}

#emailTitle {
    color: #333333;
    margin-bottom: 3px;
    font-size: 16.5px;
    font-weight: 400;
}

#email {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#email::placeholder{
    font-size: smaller;
}
#email:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#email:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 35px;
}

#passTitle {
    color: #333333;
    margin-bottom: 3px;
    font-size: 16.5px;
    font-weight: 400;
}

#pass {
    background-color: rgb(252, 252, 252);
    width: 75%;
    height: 35px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#pass::placeholder{
    font-size: smaller;
}
#pass:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#pass:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 35px;
}

#termsGroup {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    margin-top: 5px;
}
#terms {
    margin-right: 5px;
}
#termsSpan {
    color: #008fff;
    cursor: pointer;
}
#termsSpan:hover {
    text-decoration: underline;
}
#termSpan:active {
    text-decoration: underline;
    color: #09CB62;
}

#buttonInCreate {
    position: relative;
    top: 20px;
    width: 77.5%;
    height: 42px;
    border-radius: 5px;
    border: 1.5px solid #09CB62;
    background-color: #09CB62;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: white;
    cursor: pointer;
}
#buttonInCreate:hover {
    background-color: #008FFF;
    color: white;
}
#buttonInCreate:active {
    background-color: #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
    color: white;
    border: none;
}

#imgContainer {
    position: fixed;
    top: 0%;
    left: 550px;
    width: 74.5%;
    padding-top: 2%;
    padding-left: 2%;
    border-left: 1px solid black;
    background-color: #5fb991;
    height: 99vh;
}
#slogan {
    font-family: 'Inter', sans-serif;
    margin-top: -60px;
}
#imgBig {
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 25px;
}


#preSwitch {
    text-decoration: none;
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
}
