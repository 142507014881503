:root {
  overflow-x: hidden;
  height: 100vh;
}

#root {
  height: 100vh;
}

.router {
  height: 100vh;
  width: 20%;
}


