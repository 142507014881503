.homeContainer {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.list-wrapper::-webkit-scrollbar {
    display: none;
}

#signUpToday {
    border: none;
    color: black;
    background-color: #0be06e;
    border-radius: 8px;
    width: 195px;
    height: 55px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 22px;
    cursor: pointer;
}

#signUpToday:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpToday:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpToday:hover img {
    filter: invert(1);
}
#signUpToday:hover h5 {
    filter: invert(1);
}
#signUpToday:active img {
    filter: invert(1);
}
#signUpToday:active h5 {
    filter: invert(1);
}

input:focus::placeholder {
    color: transparent;
}

#styledDiv {
    background-color: #46eb7d;
    transform: skewY(-5.5deg);
    position: relative;
    top: 50px;
    width: 102%;
    left: -1%;
    height: 525px;
}

#subFrontDiv {
    width: 102%;
    height: 600px;
    position: relative;
    top: -45px;
    left: -1%;
    text-align: center;
    transform: skewY(5.5deg);
}

#subHeaderOne {
    color: black;
    position: relative;
    top: 105px;
    left: 24%;
    font-size: 35px;
}

#visualsOne {
    height: 380px;
    width: 555px;
    background-color: black;
    position: relative;
    top: 27.5px;
    left: 0%;
    border-radius: 7px;
}

#visualsTwo {
    height: 90px;
    width: 380px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    top: -42.5px;
    left: 40%;
    justify-content: center;
    display: flex;
    color: black;
}

#subTextDes {
    width: 35%;
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 14%;
    margin-top: 1.5%;
}

#thirdFrontDiv {
    height: 300px;
    width: 100%;
}
.homeContainer{
    max-width: 1920px;
    margin: auto;
}
