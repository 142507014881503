.mainMobileContainer {
    background: linear-gradient(0deg, #288150, #11ccd6, #9a32d6, #3e7cab);
    height: 4900px;
    width: 105%;
    margin-left: -2.5%; 
    padding-top: 50px;
    margin-top: -10px;
}

.list-wrapper::-webkit-scrollbar {
    display: none;
}

#desktopButton {
    border: none;
    color: black;
    background-color: #0be06e !important;
    border-radius: 8px;
    width: 90%;
    height: 45px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 22px;
    cursor: pointer;
    appearance: none;
}
#desktopButton:hover{
    background-color: #008fff !important;
    color: white !important;
}
#desktopButton:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

input:focus::placeholder {
    color: transparent;
}

#styledDiv {
    background-color: #46eb7d;
    transform: skewY(-5.5deg);
    position: relative;
    top: 50px;
    width: 102%;
    left: -1%;
    height: 525px;
}

#subFrontDiv {
    width: 102%;
    height: 600px;
    position: relative;
    top: -45px;
    left: -1%;
    text-align: center;
    transform: skewY(5.5deg);
}

#subHeaderOne {
    color: black;
    position: relative;
    top: 105px;
    left: 24%;
    font-size: 35px;
}

#visualsOne {
    height: 380px;
    width: 555px;
    background-color: black;
    position: relative;
    top: 27.5px;
    left: 0%;
    border-radius: 7px;
}

#visualsTwo {
    height: 90px;
    width: 380px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    top: -42.5px;
    left: 40%;
    justify-content: center;
    display: flex;
    color: black;
}

#subTextDes {
    width: 35%;
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 14%;
    margin-top: 1.5%;
}

#thirdFrontDiv {
    height: 300px;
    width: 100%;
}

#emailCollectionContainer {
    width: 92.5%;
    height: 350px;
    text-align: left;
    padding-left: 15px;
    background-color: white;
    padding-top: 2px;
    margin-left: 2%;
    border-radius: 10px;
}

.mobileTextTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 21px;
    width: 95%;
}

.mobileText {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 18px;
    width: 95%;
}

#emailCollection {
    width: 90%;
    border: 1px solid grey;
    border-radius: 8px;
    height: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 10px;
}

#emailSubmit {
    width: 95%;
    height: 45px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    background-color: #008FFF;
    color: white;
    margin-top: 10px;
}

.mobileFeatures {
    width: 92.5%;
    margin-left: 2%;
    margin-top: 40px;
}

#mobileFeaturesTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: white;
    margin-left: 5px;
    width: 100%;
    border-bottom: 1px solid white;
}

.featureCardMobile {
    background-color: white;
    border: none;
    border-radius: 10px;
    color: black;
    font-family: "Inter", sans-serif;
    margin-top: 15px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
}

#featureCardTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 0px;
}

#featureCardText {
    font-weight: 300;
    font-size: 17px;
    margin-top: 10px;
}


