.aboutContent {
    width: 100%;
    height: 110vh;
    margin-top: 150px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

#aboutGraphicContent {
    margin-left: 5%;
    width: 25%;
    margin-top: 10px;
}

#aboutTextContent {
    width: 60%;
    margin-left: 10%;
}

#aboutMainHeader {
    border-bottom: 1px solid black;
    width: 92%;
    margin-bottom: 30px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    padding-left: 2%;
}

#aboutMainText {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    width: 89%;
    padding-left: 2%;
}

#aboutEndingDiv {
    height: 0px;
    width: 92%;
    border-top: 0.5px solid black;
    margin-top: 32px;
    margin-bottom: 10%;
    padding-left: 2%;
}

.aboutInfoSection {
    width: 102%;
    height: 250px;
    position: absolute;
    top: 105vh;
    left: -1%;
    color: white;
    background-color: #1F1F1F;
}