.formulaToolsMain {
    align-items: left;
    position: fixed;
    height: 100%;
    width: 81%;
    left: 19%;
    top: 0px;
    margin-left: 2px;
    background-color: #333333;
    color: white;
}

.toolsContainer {
    width: 95%;
    border: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 2.5%;
    flex-wrap: wrap;
}

.toolCardMain {
    height: 200px;
    width: 260px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
    margin: 15px;
    padding-left: 10px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.toolCardMain:hover {
    border: 1px solid #09CB62;
}
.toolCardMain:active {
    box-shadow: 0px 0px 4px 2px #09CB62;
}