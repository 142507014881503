.formulaMain {
  align-items: left;
  position: absolute;
  height: 900px;
  width: 81%;
  left: 19%;
  top: 0px;
  margin-left: 2px;
  background-color: #333333;
  color: white;
}

.mainContainer {
  align-items: left;
  text-align: left;
  width: 95%;
  position: absolute;
  left: 5%;
  font-family: 'Inter', sans-serif;
}

.titleSection {
  height: 70px;
  width: 100%;
  position: relative;
  top: 20px;
  justify-content: center;
  text-align: left;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.mainTitle {
  border-bottom: 2px solid lightgrey;
  width: 30%;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
}

.buttonSection {
  align-items: left;
}

.formatTitle {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.excelButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  cursor: pointer;
}
.excelButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF
}
.excelButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.sheetsButton {
  width: 150px;
  height: 50px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-left: 10px;
  cursor: pointer;
}
.sheetsButton:hover {
  background-color: #008FFF;
  border: 1px solid #008fff;
}
.sheetsButton:active {
  background-color: #008FFF;
  border: 1px solid lightgrey;
}

.inputSection {
  position: relative;
  top: 30px;
}

.inputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainInput {
  height: 125px;
  width: 425px;
  position: relative;
  top: -10px;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  padding: 10px;
  background-color: #333333;
  color: white;
  border-radius: 8px;
}

.submitGenButton {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: #333333;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-top: 25px;
  cursor: pointer;
}
.submitGenButton:hover {
  background-color: #008fff;
  border: 1px solid #008FFF;
}
.submitGenButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

.outputSection {
  margin-top: 35px;
}

.outputLabel {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mainOutput {
  height: 75px;
  width: 425px;
  justify-content: top;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background-color: #333333;
  color: white;
  padding: 10px;
}

#copyGenButton {
  width: 300px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  margin-top: 5px;
  cursor: pointer;
  color: white;
}
#copyGenButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#copyGenButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}