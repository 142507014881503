.mainUpgrade {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}


#upgradeButton {
    border: none;
    color: black;
    background: white;
    border-radius: 5px;
    width: 105px;
    height: 35px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    position: relative;
    top: -20px;
    margin: 10px;
    cursor: pointer;
}

#upgradeButton:hover {
    background-color: #09CB62;
    color: white;
}

#upgradeButton:active {
    background-color: #008FFF;
    color: white;
    border: none;
}