.pricingFrontContainer {
    margin-top: 150px;
    height: 100%;
}

.pricingMainContent {
    display: flex;
    width: 84%;
    min-width: 410px;
    flex-wrap: wrap;
    margin-left: 8%;
    justify-content: space-evenly;
    border: 1px solid #333333;
    border-radius: 10px;
    background-color: #333333;
    margin-bottom: 75px;
}

.pricingTopText {
    width: 84%;
    margin-left: 8%;
}

#pricingSubTitle {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 35px;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#titleSpanPricing {
    color: #09CB62;
}

.corpPricingInfo {
    background-color: #333333;
    border-radius: 10px;
    border: 1px solid #333333;
    width: 84%;
    min-width: 410px;
    margin-left: 8%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 25px;
}

#corpTextContainer {
    width: 50%;
    min-width: 360px;
}

#corpHeader {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin-left: 25px;
}

#corpDescription {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-left: 25px;
}

#corpButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#corpContact {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 30%;
    min-width: 360px;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#corpContact:hover {
    background-color: #09CB62;
    color: white;
}
#corpContact:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}

.recContainer {
    display: flex;
    justify-content: flex-start;
    height: 50px;
}

#recSpan {
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: white;
    margin-top: 23px;
    border-radius: 10px;
    margin-left: 10px;
    height: 23px;
    background-color: #09CB62;
    padding-left: 7px;
    padding-right: 7px;
}

.prodContainer {
    height: 365px;
    width: 350px;
    border: 1px solid grey;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding-top: 10px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}
.prodContainer:hover {
    border: 1px solid white;
    box-shadow: 0px 0px 2px 0.5px white;
}

.prodTitle {
    width: 10%;
    margin-left: 10%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin-top: 0.5em;
}

#prodTitlePop {
    
    margin-left: 10%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin-top: 0.5em;
}

.monthlySpan {
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    margin-left: 6px;
    font-size: 14px;
}

.frontPurchase {
    border: none;
    color: white;
    background: #008FFF;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Inter Tight', sans-serif;
    margin-top: -10px;
}
.frontPurchase:hover {
    background-color: #09CB62;
    color: white;
}
.frontPurchase:active {
    background-color: #09CB62;
    color: white;
    border: none;
    box-shadow: 0px 0px 2px 0.5px #09CB62;
}