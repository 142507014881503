.pdfMain {
  align-items: left;
  position: fixed;
  height: 100%;
  width: 81%;
  left: 19%;
  top: 0px;
  margin-left: 2px;
  background-color: #333333;
  color: white;
  padding-left: 50px;
  font-size: 17px;
}

#pdfMainTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 40px;
  width: 45%;
  min-width: 250px;
  border-bottom: 1px solid white;
}

#pdfStepOne {
  font-family: 'Inter', san-serif;
  font-weight: 500;
  font-size: 17px;
}

#fileInput {
  margin-top: -10px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
}

#fileInput::file-selector-button {
  background-color: #333333;
  height: 40px;
  width: 150px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
#fileInput::file-selector-button:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#fileInput::file-selector-button:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

#pdfStepTwo {
  font-family: 'Inter', san-serif;
  font-weight: 500;
  font-size: 17px;
  margin-top: 40px;
}

#textInput {
  margin-top: -10px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 10px;
  color: white;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 19px;
}

#pdfSubmitButton {
  margin-top: 5px;
  height: 35px;
  width: 150px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 19px;
  background-color: #333333;
  border: 1px solid lightgrey;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
#pdfSubmitButton:hover {
  background-color: #008FFF;
  border: 1px solid #008FFF;
}
#pdfSubmitButton:active {
  background-color: #09CB62;
  border: 1px solid #09CB62;
}

#pdfAnswerTitle {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 22px;
  margin-top: 40px;
}

#pdfAnswerText {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  width: 50%;
}