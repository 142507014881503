#glanceContainer {
    height: 300px;
    width: 400px;
    border-radius: 5px;
    border: 1px solid #333333;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 15px;
    background-color: white;
    margin-bottom: 65px;
    margin-top: 10px;
}

#nameContainer {
    text-align: right;
    width: 97%;
    height: 52px;
    margin-top: 60px;
}

#nameTag {
    color: black;
    margin-top: 25px;
    margin-bottom: 0px;
    font-weight: 500;
}

#requests {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    height: 52px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    color: #333333;
    width: 97%;
}

#requestsTag {
    margin-top: 25px;
    font-weight: 500;
}

#requestsNumber {
    color: black;
    margin-top: 25px;
}

#toProfileButton {
    height: 30px;
    width: 97%;
    margin-left: 0%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    margin-top: 25px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#toProfileButton:hover {
    background-color: #008fff;
    color: white;
}
#toProfileButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}