:root {
    width: 102%;
}

.mainLanding {
    width: 104%;
    margin-left: -2%;
    height: 99vh;
    overflow: hidden;
    position: fixed;
}
.main::-webkit-scrollbar {
    display: none;
}

.backgroundDiv {
    background-color: #008FFF;
    transform: skewY(-7deg);
    height: 60vh;
    margin-top: 75vh;
    width: 106%;
    margin-left: -3%;
}

.mainFlowDiv {
    position: absolute;
    top: 18%;
    left: 7%;
    height: 70vh;
    width: 88%;
    display: flex;
    justify-content: space-between;
}

.textDiv {
    height: 96%;
    width: 44%;
    padding-left: 20px;
    padding-top: 15px;
    border-radius: 12px;
    background-color: white;
    color: black;
    box-shadow: 1px 2px 5px 1px rgb(87, 89, 98);
}

.mediaDiv {
    height: 100%;
    width: 45%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#mainLandingTitle {
    font-family: 'Inter', sans-serif;
    font-weight: bolder;
    font-size: 38px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#titleSpanLanding {
    background: linear-gradient(66deg, rgb(204, 53, 204), #079ade);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

#mainLandingSubTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 23px;
    margin-top: 20px;
    width: 87%;
}

#featuresLandingList {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#signUpTodayLanding {
    border: none;
    color: black;
    background-color: #0be06e;
    border-radius: 8px;
    width: 340px;
    height: 55px;
    font-size: 23px;
    justify-content: center;
    display: flex;
    position: relative;
    top: 5px;
    cursor: pointer;
}

#signUpTodayLanding:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpTodayLanding:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpTodayLanding:hover img {
    filter: invert(1);
}
#signUpTodayLanding:hover h5 {
    filter: invert(1);
}
#signUpTodayLanding:active img {
    filter: invert(1);
}
#signUpTodayLanding:active h5 {
    filter: invert(1);
}

#trustedDiv {
    width: 100%;
    height: 120px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 1px 2px 5px 1px rgb(87, 89, 98);
}