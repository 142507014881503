.feedbackContainer {
    height: 350px;
    width: 98%;
    border-radius: 7px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    padding-left: 15px;
    background: linear-gradient(33deg, #288150, #3e7cab);
    margin-top: 10px;
    padding-top: 10px;
    color: white;
}

#feedbackTitle {
    font-family: 'Inter Tight', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

#feedbackResponse {
    width: 95%;
}

#feedbackInput {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: 94%;
    padding: 5px;
    background-color: white;
}

#feedbackButton {
    margin-top: 10px;
    height: 30px;
    width: 50%;
    background-color: white;
    color: black;
    border: 1px solid grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}
#feedbackButton:hover {
    background-color: #0be06e;
    color: white;
}
#feedbackButton:active {
    background-color: #008fff;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}

