.mainPricing {
    background-color: #333333;
    height: 100%;
    width: 81%;
    position: fixed;
    top: 0px;
    left: 19%;
    margin-left: 2px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    align-items: center;
    color: white;
}

.pricingTop {
    position: absolute;
    width: 30%;
    left: 35%;
    font-weight: 500;
    height: 50;
    border-bottom: 2px solid lightgrey;
}

.pricingChart {
    width: 100%;
    text-align: center;
    justify-content: center;
    position: relative;
    top: -5%;
}

#divBackingMiddle {
    background: linear-gradient(33deg, #288150, #3e7cab);
}

#prodCurrent {
    border: none;
    color: black;
    background: white;
    border-radius: 5px;
    width: 70%;
    height: 60px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 25px;
    position: relative;
    top: -20px;
}

.pricingDashButton {
    border: 1px solid white;
    color: white;
    background: #333333;
    border-radius: 5px;
    width: 70%;
    height: 60px;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    margin-top: -20px;
}
.pricingDashButton:hover {
    background-color: #09CB62;
    color: white;
}
.pricingDashButton:active {
    background-color: #008FFF;
    color: white;
    border: none;
}