.blogMainContent {
    height: 100vh;
    width: 100%;
}

.blogMap {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.blogCardDiv {
    height: 300px;
    width: 300px;
    background: white;
    font-family: 'Inter', sans-serif;
    border: 1px solid black;
    border-radius: 10px;
    margin: 25px;
    padding: 10px;
}

#blogCardImg {
    width: 80%;
    height: 40%;
    margin-left: 10%;
    border-radius: 8px;
    margin-top: 10%;
    margin-bottom: 5%;
}

