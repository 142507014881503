@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
:root {
    height: 100%;
}

.signInDiv {
    padding: 0px;
    font-family: 'Inter', 'sans serif';

}

.googleAuthDiv1 {
    border-radius: 6px;
    background-color: white;
    position: relative;
    margin: auto;
    width: 26%;
    height: 300px;
    padding-bottom: 45px;
    font-family: 'Inter';
    margin-top: 5%;
    text-align: center;
}

.signInTitle {
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    text-align: left;
}

.emailTitle {
    color: #333333;
    font-size: 16.5px;
    text-align: left;
    font-weight: 400;
}

.email {
    background-color: rgb(252, 252, 252);
    width: 100%;
    height: 45px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter', 'sans serif';
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
.email::placeholder{
    font-size: medium;
}
.email:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
.email:hover {
    border: 2px solid #09CB62;
    height: 45px;
}

.passTitle {
    color: #333333;
    font-size: 16.5px;
    text-align: left;
    font-weight: 400;
}

#signInPass {
    background-color: rgb(252, 252, 252);
    width: 100%;
    height: 45px;
    border: 2px solid #818181;
    border-radius: 5px;
    font-size: 19px;
    font-family: 'Inter';
    text-align: left;
    padding-left: 8px;
    color: #818181;
}
#signInPass::placeholder{
    font-size: medium;
}
#signInPass:focus {
    box-shadow: 0px 0px 3px 1.75px #008FFF;
    color: black;
}
#signInPass:hover {
    border: 2px solid #09CB62;
    /* background-color: rgb(248, 248, 248); */
    height: 45px;
}

.buttonInSign {
    position: relative;
    top: 5px;
    width: 82.5%;
    height: 45px;
    border-radius: 7px;
    border: 1.5px solid #09CB62;
    background-color: #09CB62;
    font-family: 'Inter';
    font-size: 19px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-left: 8px;
}
.buttonInSign:hover {
    background-color: #008FFF;
    color: white;
}
.buttonInSign:active {
    background-color: #008FFF;
    box-shadow: 0px 0px 4px 2px #00B4FF;
    color: white;
    border: none;
}
.headerContainer {
    display: flex;
    justify-content: flex-start;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 0%;
    margin-left: 2%;
    margin-top: 24px;
    width: 400px;
    padding-bottom: 6px;
    width: 98%;
}
.siteLogoCreate {
    cursor: pointer;
}
.siteTitle {
    margin-left: 8px;
    margin-top: 7px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}
.preSwitch{
    font-family: 'Inter', 'sans serif';
}
.learnMore{
    text-align: right;
    font-family: 'Inter','sans serif';
}

#passResetLink {
    width: 100%;
    text-align: right;
    margin-left: 2.5%;
    color: grey;
    cursor: pointer;
}
#passResetLink:hover {
    text-decoration: underline;
    color: black;
}
#passResetLink:active {
    text-decoration: underline;
    color: #008FFF;
}
