#solutionsRoot {
    background-color: black;
    width: 102%;
    margin-left: -1%;
}

#solutionsHeadContainer {
    width: 100%;
    height: 500px;
    margin-top: 100px;
    background-color: #09CB62;
    color: black;
    margin-bottom: 0%;
    display: flex;
    justify-content: space-between;
}

#solutionsHeadText {
    margin-left: 17.5%;
    width: 25%;
    margin-top: 75px;
}

#solutionsSection{
    font-family: Inter, sans-serif;
    font-size: 19px;
    letter-spacing: 1px;
}

#solutionsTitle {
    font-size: 35px;
    font-weight: 600;
    font-family: Inter Tight, sans-serif;
    color: white;
    letter-spacing: 1px;
    line-height: 45px;
    margin-top: 25px;
}

#signUpTodaySolutions {
    border: none;
    color: black;
    background-color: white;
    border-radius: 8px;
    width: 195px;
    height: 55px;
    font-size: 23px;
    font-family: Helvetica, Arial, sans-serif;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
#signUpTodaySolutions:hover{
    background-color: #008fff !important;
    color: white !important;
}
#signUpTodaySolutions:active {
    background-color: #008FFF !important;
    color: white;
    box-shadow: 0px 0px 4px 2px #00B4FF;
}
#signUpTodaySolutions:hover img {
    filter: invert(1);
}
#signUpTodaySolutions:hover h5 {
    filter: invert(1);
}
#signUpTodaySolutions:active img {
    filter: invert(1);
}
#signUpTodaySolutions:active h5 {
    filter: invert(1);
}

#solutionsHeadGraphic {
    width: 40%;
    margin-right: 10%;
}

#solutionsMainContainer {
    background-color: black;
    width: 100%;
    margin-top: -1.5%;
}

#solutionsBufferZone {
    height: 10px;
    width: 100%;
    margin-top: 0%;
}

#cardSectionSolutions {
    margin-bottom: 55px;
}

.cardHolder {
    display: flex;
    justify-content: space-between;
    color: white;
    width: 100%;
    height: 160px;
    border-top: 1px solid white;
    margin-bottom: 15px;
}

#cardHolderLast {
    border-bottom: 1px solid white;
}

#industryRight {
    font-size: 23px;
    font-weight: 500;
    font-family: Inter, sans-serif;
    text-align: left;
    width: 12%;
    border-right: 1px solid white;
    margin-top: 15px;
    margin-left: 1.5%;
}

#solutionsRight {
    font-size: 21px;
    font-weight: 300;
    font-family: Inter, sans-serif;
    text-align: right;
    width: 45%;
    margin-right: 2.5%;
}

#industryRightText {
    margin-top: 0%;
}

#solutionsBottomCap {
    width: 100%;
    height: 5px;
    border-top: 1px solid white;
}